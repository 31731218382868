import {
    ref,
    reactive,
    del,
    set,
    computed,
    onActivated,
    onMounted,
    watch,
    getCurrentInstance,
    defineExpose,
    onBeforeUnmount,
    nextTick,
} from "@vue/composition-api";
import VueForm, { fieldProps, vueUtils } from "@lljj/vue-json-schema-form";
// 圖片上傳組件
import TemplateUploadImgFile from "../components/templateUploadImgFile.vue";
// 影片上傳組件
import TemplateUploadVideoFile from "../components/templateUploadVideoFile.vue";

import SideBar from "@/views/templateSetting/components/SideBar.vue";
import store from "@/store";

export default {
    name: "templateIndexCreataeOrder",
    components: {
        TemplateUploadImgFile,
        TemplateUploadVideoFile,
        SideBar,
        VueForm,
    },
    props: {},
    emits: [],
    setup(props, { emit }) {
        const testData = ref("is work");
        const templateForm = ref({
            templateSelect: {
                style: "tmp1",
            },
        });

        function consoleLog(getForm) {
            console.log(getForm());
        }

        const limitFileSize = ref(
            store.state.utilityStore.uiSwitchSettingDatas.upload_photo_size ??
                process.env.VUE_APP_UPLOAD_PHOTO_SIZE
        );

        const templateSchema = ref({
            type: "object",
            properties: {
                templateSelect: {
                    title: "樣板選擇",
                    type: "object",
                    properties: {
                        style: {
                            title: "當前選擇樣板",
                            type: "string",
                            enum: ["tmp1", "tmp2"],
                            enumNames: ["樣板1", "樣板2"],
                            "ui:width": "40%",
                        },
                    },
                },
            },
        });
        const datasForm = ref({
            data: {
                title: "test title",
                description: "test description",
            },
        });

        const datasSchema = ref({
            title: "開立訂單標題",
            type: "object",
            required: ["data"],
            properties: {
                data: {
                    type: "object",
                    required: ["title", "description"],
                    properties: {
                        title: {
                            title: "標題",
                            type: "string",
                            "err:required": "請輸入標題",
                        },
                        description: {
                            title: "描述",
                            type: "string",
                            "err:required": "請輸入描述",
                        },
                    },
                },
            },
        });
        const dataUiSchema = ref({
            data: {
                title: {},
                description: {},
            },
        });

        const customRule = ref(({ field, value, rootFormData, callback }) => {
            const rules = [
                {
                    rule: "password2",
                    validator(value, rootFormData) {
                        if (value !== rootFormData.password)
                            return "密码输入不一致";
                    },
                },
                {
                    rule: /dataList\.\d+\.url/,
                    validator(value, rootFormData) {
                        const regex =
                            /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g;

                        if (!regex.test(value)) {
                            return "請輸入正確網址";
                        }
                    },
                },
            ];

            for (const ruleItem of rules) {
                // String | Regx
                if (
                    (String(ruleItem.rule) === ruleItem.rule &&
                        ruleItem.rule === field) ||
                    (Object.prototype.toString.call(ruleItem.rule) ===
                        "[object RegExp]" &&
                        ruleItem.rule.test(field))
                ) {
                    const error = ruleItem.validator(value, rootFormData);
                    // 停止檢查
                    if (error) return callback(error);
                }
            }
            return callback();
        });

        const jsonData = ref({});
        function addClass() {}
        function handleSubmit(formData) {
            if (window.getSelection()) {
                console.log("formData: =>", formData);
                jsonData.value = formData;
            }
        }

        return {
            testData,
            addClass,
            templateForm,
            templateSchema,
            datasForm,
            datasSchema,
            dataUiSchema,
            consoleLog,
            customRule,
            handleSubmit,
        };
    },
    render() {
        const scopedSlots = {
            default: (data) => (
                <div>
                    <pre
                        style={{
                            backgroundColor: "#eee",
                            overflowX: "scroll",
                            width: "800px",
                        }}
                    >
                        {JSON.stringify(data.formData, null, 4)}
                    </pre>
                    <p>
                        <el-button
                            type="primary"
                            onClick={() => this.consoleLog(data.formRefFn)}
                        >
                            Submit
                        </el-button>
                    </p>
                </div>
            ),
        };
        return (
            <div class="flex mx-5 my-5">
                <SideBar
                    btnText={"+ 新增類別"}
                    clickBtn={() => this.addClass()}
                />
                <div class="pb-5 flex-1">
                    <div class="shadow-lg bg-white rounded-lg ml-3 pb-5 relative p-5">
                        <VueForm
                            vModel={this.templateForm}
                            class="text-red-500"
                            schema={this.templateSchema}
                            scopedSlots={scopedSlots}
                        ></VueForm>
                        <VueForm
                            vModel={this.datasForm}
                            class="text-red-500"
                            schema={this.datasSchema}
                            ui-schema={this.dataUiSchema}
                            custom-rule={this.customRule}
                            scopedSlots={scopedSlots}
                        ></VueForm>
                    </div>
                </div>
            </div>
        );
    },
};
